import * as Sentry from '@sentry/nextjs';
import Error from 'next/error';
const CustomErrorComponent = props => {
  return <Error statusCode={props.statusCode} data-sentry-element="Error" data-sentry-component="CustomErrorComponent" data-sentry-source-file="_error.jsx" />;
};
CustomErrorComponent.getInitialProps = async contextData => {
  if (contextData.err) {
    console.error('[Server-side error] Caught in global err handler _error.js:', {
      ...contextData,
      req: 'obmitted',
      res: 'obmitted'
    });
  }
  // In case this is running in a serverless function, await this in order to give Sentry
  // time to send the error before the lambda exits
  await Sentry.captureUnderscoreErrorException(contextData);

  // This will contain the status code of the response
  return Error.getInitialProps(contextData);
};
export default CustomErrorComponent;